<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm6 offset-sm3 mt-3>
        <h1>Logout</h1>

          <v-flex class="text-xs-center" mt-5>
            <v-alert
              type="success"
            >Sie wurden abgemeldet</v-alert>
          </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: 'Logout',
  data () {
    return {};
  },
  created() {
    localStorage.setItem('token', '');
    localStorage.setItem('role', '');
    this.$emit('changeLoginState', '');
  }
}
</script>
